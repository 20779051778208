// src/components/Navbar.js

import React, { useState } from 'react';
import { Search, Menu, Church } from 'lucide-react';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <header className="bg-white shadow-md">
            <div className="container mx-auto px-4 py-3 flex items-center justify-between">
                <div className="flex items-center">
                    <Church className="text-blue-500 mr-2" size={24} />
                    <span className="font-bold text-xl">Diosidencias</span>
                </div>

                {/* Desktop Navigation */}
                <nav className="hidden lg:flex flex-grow justify-center space-x-6">
                    {/* <Link to="/" className="text-gray-600 hover:text-blue-500">Inicio</Link>
                    <Link to="/about" className="text-gray-600 hover:text-blue-500">Sobre Nosotros</Link>
                    <Link to="/privacy-policy" className="text-gray-600 hover:text-blue-500">Política de Privacidad</Link>
                    <Link to="/terms-of-service" className="text-gray-600 hover:text-blue-500">Términos de Servicio</Link>
                    <Link to="/contact" className="text-gray-600 hover:text-blue-500">Contacto</Link> */}
                </nav>

                {/* Search and Mobile Menu Button */}
                {/* <div className="flex items-center space-x-4">
                    <div className="relative">
                        <input
                            type="text"
                            placeholder="Buscar..."
                            className="border border-gray-300 rounded-full py-1 px-4 w-32 md:w-64 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <Search className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500" size={20} />
                    </div>
                    <button
                        className="lg:hidden text-gray-500 focus:outline-none"
                        onClick={toggleMobileMenu}
                    >
                        <Menu size={20} />
                    </button>
                </div> */}
            </div>

            {/* Mobile Menu */}
            {isMobileMenuOpen && (
                <div className="lg:hidden bg-white shadow-md absolute top-14 left-0 w-full z-50">
                    <nav className="flex flex-col space-y-4 p-4">
                        <Link to="/" className="text-gray-600 hover:text-blue-500">Inicio</Link>
                        <Link to="/about" className="text-gray-600 hover:text-blue-500">Sobre Nosotros</Link>
                        <Link to="/privacy-policy" className="text-gray-600 hover:text-blue-500">Política de Privacidad</Link>
                        <Link to="/terms-of-service" className="text-gray-600 hover:text-blue-500">Términos de Servicio</Link>
                        <Link to="/contact" className="text-gray-600 hover:text-blue-500">Contacto</Link>
                    </nav>
                </div>
            )}
        </header>
    );
};

export default Navbar;
