// src/pages/Home.js

import React, { useEffect, useState } from 'react';
import { FileDown, Church } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Sponsors from '../components/Sponsors';

const Home = () => {
  const [pdfFiles, setPdfFiles] = useState([]);

  useEffect(() => {
    const fetchPdfFiles = async () => {
      try {
        const response = await fetch('/data/pdf/infopdf.json');
        const data = await response.json();
        setPdfFiles(data);
      } catch (error) {
        console.error('Error al cargar los archivos PDF:', error);
      }
    };

    fetchPdfFiles();
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />

      {/* Main Content */}
      <main className="flex-grow container mx-auto px-4 py-8">
        {/* Hero Section */}
        <div className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded-lg shadow-lg p-8 mb-8">
          <div className="flex items-center justify-between">
            <div className='flex flex-col gap-2'>
              <h1 className="text-3xl font- border-b border-blue-400">Diosidencias</h1>
              <h1 className="text-4xl font-bold">HOLY NAME OF JESUS PARISH</h1>
              <div className='flex flex-col gap-0 border-t border-b border-blue-400 px-4'>
                <p className="text-lg">WEST HAZLETON - DIÓCESIS DE SCRANTON – PENNSYLVANIA</p>
                <p className="text-lg">Accede a nuestra colección de ediciones PDF descargables</p>
              </div>
            </div>
            <Church size={120} className="hidden md:block shadow border rounded-lg p-2" />
          </div>
        </div>

        {/* PDF List Section */}
        <div>
          <h2 className="text-2xl font-bold mb-4">Ediciones Disponibles</h2>
          <ul className="space-y-2">
            {pdfFiles.length > 0 ? (
              pdfFiles.map((file, index) => (
                <li key={index} className="flex items-center justify-between bg-white p-3 rounded-lg shadow border border-blue-400 mr-4">
                  <span className="text-md">{file.name}</span>
                  <a
                    href={file.url}
                    download
                    className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
                  >
                    <FileDown className="mr-2" size={20} />
                    Descargar
                  </a>
                </li>
              ))
            ) : (
              <li className="text-center text-gray-600">Cargando documentos...</li>
            )}
          </ul>
        </div>
      </main>
      {/* <Sponsors /> */}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Home;
